import axios from "axios";
// import { mapGetters } from "vuex";
// import moment from "moment";

const loggedIn = localStorage.getItem("user");

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

/*
Re inject the token in axios when 
*/
function initClient(loggedin) {
  if (
    apiClient.defaults.headers.common["Authorization"] &&
    apiClient.defaults.headers.common["Authorization"].length > 8
  )
    return;
  else {
    var userdata = JSON.parse(loggedin);
    apiClient.defaults.headers.common["Authorization"] =
      "Token " + userdata.token;
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + userdata.tenant + ".api.controltss.com.ar";
    }
  }
}

if (loggedIn) initClient(loggedIn);

export default {
  login(user_credentials) {
    if (process.env.NODE_ENV == "development") {
      apiClient.defaults.baseURL = "http://127.0.0.1:8000";
    } else {
      apiClient.defaults.baseURL =
        "https://" + user_credentials.tenant + ".api.controltss.com.ar";
    }
    //delete user_credentials.tenant;
    apiClient.defaults.headers.common["Authorization"] = null;
    return apiClient.post("/auth/", user_credentials);
  },

  setToken(token) {
    apiClient.defaults.headers.common["Authorization"] = "Token " + token;
  },

  getAccesos(
    desde,
    hasta,
    texto = null,
    tipo = null,
    sinegreso = false,
    page = "1"
  ) {
    let urlstr = "/visitas/accesos/?page=" + page;
    if (!desde || !hasta) return apiClient.get(urlstr);

    urlstr += "&desde=" + desde + "&hasta=" + hasta;
    if (texto) urlstr += "&buscar=" + texto;
    if (tipo) urlstr += "&tipo=" + tipo;
    if (sinegreso) urlstr += "&egreso=" + sinegreso;

    return apiClient.get(urlstr);
  },

  getPagination(url) {
    return apiClient.get(url);
  },

  getAcceso(id) {
    return apiClient.get("/visitas/acceso/" + id);
  },

  setEgreso(id) {
    return apiClient.patch("/visitas/acceso/" + id + "/setEgreso");
  },

  getEmpresas(texto = null, page = "1") {
    if (page) page = "?page=" + page;

    if (!texto) return apiClient.get("/visitas/empresas");
    else {
      return apiClient.get("/visitas/empresas" + page + "&buscar=" + texto);
    }
  },

  getPersonas(texto = null, tipo = null, page = "1") {
    if (page) page = "?page=" + page;

    if (!texto && !tipo) return apiClient.get("/visitas/personas" + page);
    else if (texto && tipo)
      return apiClient.get(
        "/visitas/personas" + page + "&buscar=" + texto + "&tipo=" + tipo
      );
    else if (texto)
      return apiClient.get("/visitas/personas" + page + "&buscar=" + texto);
    else return apiClient.get("/visitas/personas" + page + "&tipo=" + tipo);
  },

  getPersona(id) {
    return apiClient.get("/visitas/persona/" + id + "/");
  },

  getPersonaByDocumentoIngreso(documentoNro) {
    return apiClient.get(
      "/visitas/personas?documento=" + documentoNro + "&ingreso=1"
    );
  },

  getTiposPersona() {
    return apiClient.get("/visitas/personas/tipos");
  },

  getGeneros() {
    return apiClient.get("/visitas/personas/generos");
  },

  getTiposDocumento() {
    return apiClient.get("/visitas/personas/documento/tipos");
  },

  getTiposTelefono() {
    return apiClient.get("/visitas/personas/telefonos/tipos");
  },

  getTelefonos() {
    return apiClient.get("/telefonos");
  },

  postIngreso(acceso) {
    //acceso.Ingreso = this.getCurrentDateTime();
    return apiClient.post("visitas/acceso/new", acceso);
  },

  changePassword(credentials) {
    return apiClient.patch("/auth/ChangePassword", credentials);
  },

  //      "/api/persona/" + id
  putPersona(persona) {
    return apiClient.put("/visitas/persona/" + persona.id + "/", persona);
  },
  postEmpresa(empresa) {
    return apiClient.post("/visitas/empresa/new", empresa);
  },
  postPersona(persona) {
    return apiClient.post("/visitas/persona/new", persona);
  },

  postPersonaFoto(foto) {
    return apiClient.post("/visitas/persona/" + foto.persona + "/foto/", foto);
  },

  putPersonaFoto(foto) {
    return apiClient.put("/visitas/persona/" + foto.persona + "/foto/", foto);
  },

  getPersonaFoto(id) {
    return apiClient.get("/visitas/persona/" + id + "/foto/");
  },

  // computed: {
  //   ...mapGetters(["getToken"])

  // },
  // getCurrentDateTime() {
  //   return moment().format("DD/MM/YYYY HH:mm");
  // },
};
