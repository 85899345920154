import Vue from "vue";
import VueRouter from "vue-router";
// import LoginPage from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ingreso",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      hideForAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/permisosError",
    name: "PermisosError",
    meta: { requiresAuth: true, permError: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PermisosError.vue"),
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PasswordChangePage.vue"),
  },
  {
    path: "/ingreso/:documento?",
    name: "Ingreso",
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "ingreso" */ "../views/Ingreso.vue"),
  },
  {
    path: "/accesos/:documento?",
    name: "Accesos",
    props: true,
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "accesos" */ "../views/Accesos.vue"),
  },
  {
    path: "/personas",
    name: "Personas",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "Personas" */ "../views/Personas.vue"),
  },
  {
    path: "/personas/alta",
    name: "PersonaAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "PersonaAlta" */ "../views/PersonaAlta.vue"),
  },

  {
    path: "/persona/:id/editar",
    name: "PersonaEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "PersonaAlta" */ "../views/PersonaEdit.vue"),
  },

  {
    path: "/persona/:id",
    name: "PersonaDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/PersonaDetalle.vue"
      ),
  },
  {
    path: "/acceso/:id",
    name: "AccesoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/AccesoDetalle.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  let permission = false;
  if (loggedIn) {
    permission = JSON.parse(loggedIn).permission;
  } else permission = false;

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => !record.meta.permError) &&
    loggedIn &&
    !permission
  ) {
    next("/permisosError");
  } else if (to.matched.some((record) => record.meta.hideForAuth) && loggedIn) {
    next("/");
  } else next();
});

export default router;
